import { React, Component } from "react";
import {
	AmpStoryGridLayer,
	AmpStory,
	AmpStoryPage,
	AmpImg,
	AmpHeader,
} from "react-google-stories";
import SpinnerLoader from "./loader";

class StoriesComponent extends Component {
	constructor() {
		super();
		this.instaLink = `https://instastories.artefaktas.workers.dev/api`;
		this.state = {
			instadata: null,
		};
		this.messages = {
			readMore: `Skaityti`,
			visitInsragram: `Instagram`,
			instagramNotitle: `Autorius nepridėjo pavadinimo, bet gražu... Nepasiginčysi`,
			instagramNoContent: `Istorijų nėra`,
		};
		this.ampStyle = {
			width: 100 + `%`,
			height: 100 + `vh`,
			color: `white`,
		};
		this.defaultImage = `../images/animal-world-attention-branch-267074.jpg`;
		this.extras = {
			title: `Artefaktas.eu stories`,
			publisher: `Artefaktas Blog`,
			logoLink: `../images/128.png`,
		};
	}

	componentDidMount() {
		let instaLink = this.instaLink;

		async function getInstaData() {
			let res = await fetch(instaLink);
			let js = await res.json();
			return js.data;
		}

		getInstaData().then((d) => {
			this.setState({ instadata: d });
		});
	}

	render() {
		return (
			<div>
				{this.state.instadata !== null && this.state.instadata.length >= 0 ? (
					<AmpStory
						title={this.extras.title}
						publisher={this.extras.publisher}
						publisher-logo-src={this.extras.logoLink}
						style={this.ampStyle}
					>
						{this.state.instadata !== null &&
						this.state.instadata.length > 0 &&
						this.state.data !== "" ? (
							this.state.instadata.map((r, i) => {
								return (
									<AmpStoryPage id={i} key={i}>
										<AmpStoryGridLayer template="fill">
											{r.media_type === "IMAGE" ? (
												<AmpImg
													src={r.media_url}
													height="720"
													width="1280"
													layout="responsive"
												/>
											) : r.media_type === "VIDEO" ? (
												<amp-video
													autoplay
													loop
													grid-area="middle-third"
													width="1280"
													height="720"
													poster={r.thumbnail_url}
													layout="responsive"
													alt="artefaktasampvideo"
												>
													<source src={r.media_url} type="video/mp4" />
												</amp-video>
											) : (
												""
											)}
										</AmpStoryGridLayer>
										<AmpStoryGridLayer template="vertical">
											<AmpHeader
												level={3}
												title={
													r.caption
														? String(r.caption)
																.replaceAll(":", "")
																.replaceAll("(", "")
																.replaceAll(")", "")
																.replaceAll("#", "")
																.replaceAll("+", "")
																.replaceAll("-", "")
														: this.messages.instagramNotitle
												}
											/>
										</AmpStoryGridLayer>
										{i !== 0 ? (
											<amp-story-cta-layer>
												<a href={r.permalink}>{this.messages.visitInsragram}</a>
											</amp-story-cta-layer>
										) : (
											""
										)}
									</AmpStoryPage>
								);
							})
						) : (
							<AmpStoryPage id="0" key="0">
								<AmpStoryGridLayer template="fill">
									<AmpImg
										src={this.defaultImage}
										height="720"
										width="1280"
										layout="responsive"
									/>
								</AmpStoryGridLayer>
								<AmpStoryGridLayer template="vertical">
									<AmpHeader
										level={3}
										title={this.messages.instagramNoContent}
									/>
								</AmpStoryGridLayer>
							</AmpStoryPage>
						)}
					</AmpStory>
				) : (
					<SpinnerLoader />
				)}
			</div>
		);
	}
}

export default StoriesComponent;
